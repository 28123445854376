/** @format */

// For some reason, the default scroll restoration behavior in Gatsby is not working and
// the page is not scrolled to the top when navigating between pages. This is a workaround
// to force the page to scroll to the top when navigating between pages.
exports.shouldUpdateScroll = ({ routerProps }) => {
  // If explicitly requested, it is possible to not scroll to the top on navigation
  if (routerProps.location && routerProps.location.state && routerProps.location.state.keepScrollPosition) {
    return false
  }
  window.scrollTo({ top: 0, behavior: "instant" })
  return false
}

// This function is called when the client is ready to hydrate the page. It is used to
// store the UTM parameters (if existent) in the session storage so they can be used later in the
// contact form to be submitted along with a lead's information. This is useful to track the
// source of the leads (paid ads, social media, etc).
exports.onInitialClientRender = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const utmParams = {
    utm_source: urlParams.get("utm_source") || "",
    utm_medium: urlParams.get("utm_medium") || "",
    utm_campaign: urlParams.get("utm_campaign") || "",
    utm_term: urlParams.get("utm_term") || "",
    utm_content: urlParams.get("utm_content") || "",
  }

  sessionStorage.setItem("utmParams", JSON.stringify(utmParams))
}
